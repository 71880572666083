// TODO: handle 404s

import React, {useState, useEffect, useRef} from 'react';
import {quote} from 'shlex';
import {
  ThemeContext,
  Anchor,
  Stack,
  Drop,
  CheckBox,
  Grid,
  Text,
  Box,
  Button,
  Grommet,
  Heading,
  Layer,
  Form,
  FormField,
  TextInput,
  Collapsible,
  Image,
  ResponsiveContext,
  TextArea,
} from 'grommet';
import {
  Add,
  FormDown,
  FormNext,
  Github,
  Copy,
  Logout,
  Share,
  FormTrash,
} from 'grommet-icons';
import {gql} from 'apollo-boost';
import {ApolloClient} from 'apollo-client';
import {split} from 'apollo-link';
import {HttpLink} from 'apollo-link-http';
import {getMainDefinition} from 'apollo-utilities';

import {ApolloProvider} from 'react-apollo';
import {Query, Mutation} from 'react-apollo';
import {InMemoryCache} from 'apollo-cache-inmemory';
import copy from 'copy-to-clipboard';
import {ReactComponent as Logo} from './logo.svg';

import diceware from 'diceware';
import {
  BrowserRouter as Router,
  Link,
  Route,
  withRouter,
  Switch,
} from 'react-router-dom';
import urlParse from 'url-parse';
import {hp} from './Themes';
import {distanceInWordsStrict, distanceInWords} from 'date-fns';
import {oc} from 'ts-optchain';
import {WebSocketLink} from 'apollo-link-ws';
import {FixedSizeList} from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import Favico from './favico';

const FAVICON = new Favico({
  position: 'up',
  animation: 'none',
  fontFamily: "'HPSimplified',Arial,sans-serif",
  bgColor: '#006996',
});

const ENV = process.env.NODE_ENV;

const BASE_URL =
  ENV === 'production' ? 'https://websmee.com' : 'http://localhost:5010';

// const OG_APP_ID =
//   ENV === 'production'
//     ? '313971b5-6735-42a7-8378-8a761038ed3c'
//     : 'fcabb730-89f3-400d-952a-b20ab184c18d';

function hookUrl(path) {
  return BASE_URL + '/hook/' + path;
}

const wsLink = new WebSocketLink({
  uri:
    ENV === 'production'
      ? 'wss://websmee.com/graphql'
      : 'ws://localhost:5010/graphql',
  options: {
    reconnect: true,
  },
});

const httpLink = new HttpLink({
  uri: '/graphql',
});

const link = split(
  ({query}) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({dataIdFromObject: object => object.id || null}),
});

const SUMMARY_BOX_HEIGHT = 70;

const endpointFragment = gql`
  fragment EndpointDetails on Endpoint {
    id
    path
    contentType
    statusCode
    responseBody
    lastRequestAt
  }
`;

const endpointsQuery = gql`
  query EndpointsQuery {
    endpoints(first: 1000) {
      nodes {
        ...EndpointDetails
      }
    }
  }
  ${endpointFragment}
`;

const meQuery = gql`
  query MeQuery {
    people {
      nodes {
        id
        githubUserId
        githubLogin
        githubAvatarUrl
      }
    }
  }
`;

const teamsQuery = gql`
  query TeamsQuery {
    teams {
      nodes {
        name
        id
      }
    }
  }
`;

const createEndpointMutation = gql`
  mutation CreateEndpoint(
    $path: String!
    $teamId: UUID!
    $statusCode: Int! = 200
    $contentType: String! = "text/plain"
    $responseBody: String! = ""
  ) {
    createEndpoint(
      input: {
        endpoint: {
          path: $path
          teamId: $teamId
          statusCode: $statusCode
          contentType: $contentType
          responseBody: $responseBody
        }
      }
    ) {
      clientMutationId
      endpoint {
        ...EndpointDetails
      }
    }
  }
  ${endpointFragment}
`;

const deleteRequestMutation = gql`
  mutation DeleteRequest($id: UUID!) {
    deleteRequest(input: {id: $id}) {
      deletedRequestNodeId
    }
  }
`;

class CopyToClipboard extends React.PureComponent {
  state = {
    showCopiedTooltip: false,
  };
  ref = React.createRef();
  _copy = () => {
    copy(this.props.text, {format: 'text/plain'});
    this.setState({showCopiedTooltip: true});
    setTimeout(() => this.setState({showCopiedTooltip: false}), 750);
  };
  render() {
    return (
      <>
        <Button
          key="button"
          ref={this.ref}
          style={{width: this.props.size, height: this.props.size}}
          plain
          icon={
            <Copy
              size={this.props.size}
              style={{backgroundColor: this.props.backgroundColor}}
            />
          }
          onClick={this._copy}
        />
        {this.state.showCopiedTooltip && this.ref.current ? (
          <Drop
            align={{bottom: 'top'}}
            target={this.ref.current}
            style={{borderRadius: '4px', padding: 4}}>
            <Text size="small">Copied to clipboard!</Text>
          </Drop>
        ) : null}
      </>
    );
  }
}

function CreateEndpointModalImpl({onClose, history}) {
  const [formError, setFormError] = useState('');
  const [pathValue, setPathValue] = useState(
    diceware(4)
      .split(' ')
      .join('-'),
  );
  const [responseCodeValue, setResponseCodeValue] = useState('200');
  const [contentTypeValue, setContentTypeValue] = useState('text/plain');
  const [responseBodyValue, setResponseBodyValue] = useState('');
  const [advancedFieldsOpen, setAdvancedFieldsOpen] = useState(false);
  return (
    <Query query={teamsQuery}>
      {({loading, error, data}) => {
        if (loading) {
          return null;
        }
        const teamId =
          data && data.teams && data.teams.nodes[0] && data.teams.nodes[0].id;
        return (
          <Layer
            position="center"
            modal
            onClickOutside={onClose}
            onEsc={onClose}>
            <Box pad="medium" gap="small" width="medium">
              <Heading level={3} margin="none">
                Create Endpoint
              </Heading>
              <Mutation
                mutation={createEndpointMutation}
                update={(cache, {data}) => {
                  const {endpoints} = cache.readQuery({
                    query: endpointsQuery,
                  });
                  cache.writeQuery({
                    query: endpointsQuery,
                    data: {
                      endpoints: {
                        __typename: 'EndpointsConnection',
                        nodes: (endpoints.nodes || []).concat([
                          data.createEndpoint.endpoint,
                        ]),
                      },
                    },
                  });
                }}>
                {(createEndpoint, {loading, error}) => (
                  <Form
                    onSubmit={async () => {
                      try {
                        const result = await createEndpoint({
                          variables: {
                            path: pathValue,
                            teamId,
                            statusCode: parseInt(responseCodeValue, 10),
                            contentType: contentTypeValue,
                            responseBody: responseBodyValue,
                          },
                        });
                        const path = result.data.createEndpoint.endpoint.path;
                        onClose();
                        history.push(`/hook/${path}`);
                      } catch (e) {
                        const gqlError = oc(e).graphQLErrors[0]();
                        if (gqlError) {
                          if (
                            gqlError.message.indexOf(
                              'valid_path_characters',
                            ) !== -1
                          ) {
                            setFormError(
                              'Only letters, numbers, and dashes are allowed.',
                            );
                            return;
                          }
                          if (
                            gqlError.message.indexOf(
                              'path_length_characters',
                            ) !== -1
                          ) {
                            setFormError(
                              'Path must be 3 characters or longer and less than 256 characters.',
                            );
                            return;
                          }
                          if (gqlError.message.indexOf('duplicate') !== -1) {
                            setFormError(
                              'That path already exists. Please choose a new one.',
                            );
                            return;
                          }
                        }
                        setFormError('Unknown error');
                        console.error('Error in create endpoint', e);
                      }
                    }}>
                    <FormField
                      error={formError}
                      label="Path"
                      name="path"
                      required
                      value={pathValue}
                      onChange={e => setPathValue(e.target.value)}
                      validate={value => {
                        if (!value) {
                          return 'Path is required.';
                        }
                        if (!value.match(/^[a-z0-9-]+/i)) {
                          return 'Only letters, numbers, and dashes are allowed.';
                        }
                        if (value.length < 3) {
                          return 'Path must be 3 characters or longer.';
                        }
                        if (value.length > 255) {
                          return 'Path must be less than 256 characters.';
                        }
                      }}></FormField>

                    <MenuButton
                      label="Advanced options"
                      open={advancedFieldsOpen}
                      onClick={() => setAdvancedFieldsOpen(!advancedFieldsOpen)}
                    />
                    <Collapsible open={advancedFieldsOpen}>
                      <>
                        <FormField
                          label="Response code"
                          name="code"
                          help="The HTTP response code that Websmee will return in its response."
                          value={responseCodeValue}
                          validate={value => {
                            if (value && !value.match(/[0-9][0-9][0-9]/i)) {
                              return 'Response code must be a 3-digit HTTP response code.';
                            }
                          }}
                          onChange={e => setResponseCodeValue(e.target.value)}
                        />
                        <FormField
                          label="Content type"
                          name="content-type"
                          help="The Content-Type header that Websmee will return in its response."
                          value={contentTypeValue}
                          validate={value => {
                            if (value.length > 1024) {
                              return 'Content type length is too long.';
                            }
                          }}
                          onChange={e => setContentTypeValue(e.target.value)}
                        />
                        <FormField
                          label="Response body"
                          name="response-body"
                          help="The body that Websmee will return in its response."
                          value={responseBodyValue}
                          validate={value => {
                            if (value.length > 1024) {
                              return 'Response body length is too long.';
                            }
                          }}
                          onChange={e => setResponseBodyValue(e.target.value)}>
                          <TextArea
                            value={responseBodyValue}
                            onChange={e => setResponseBodyValue(e.target.value)}
                          />
                        </FormField>
                      </>
                    </Collapsible>
                    <Button
                      disabled={loading}
                      type="submit"
                      label="Create"
                      primary={true}
                      margin={{top: 'small'}}
                    />
                  </Form>
                )}
              </Mutation>
            </Box>
          </Layer>
        );
      }}
    </Query>
  );
}

async function logout() {
  await fetch('/logout', {
    method: 'POST',
  });
  const loc = window.location;
  window.location = loc;
}

class Avatar extends React.Component {
  ref = React.createRef();
  state = {
    showOptions: false,
    endpointModalOpen: false,
  };
  render() {
    return (
      <Query query={meQuery}>
        {({data}) => {
          if (!oc(data).people.nodes.length()) {
            return null;
          }
          const avatarUrl = oc(data).people.nodes[0].githubAvatarUrl();
          const login = oc(data).people.nodes[0].githubLogin();
          return (
            <>
              {this.state.endpointModalOpen ? (
                <CreateEndpointModal
                  onClose={() => this.setState({endpointModalOpen: false})}
                />
              ) : null}
              <Box
                onClick={() =>
                  this.setState({showOptions: !this.state.showOptions})
                }
                onBlur={() => this.setState({showOptions: false})}
                ref={this.ref}
                round="xsmall"
                style={{
                  height: 32,
                  width: 32,
                  overflow: 'hidden',
                  cursor: 'pointer',
                }}>
                <Image fit="cover" src={avatarUrl} />
              </Box>
              {this.ref.current && this.state.showOptions ? (
                <Drop
                  style={{margin: 12, paddingTop: 12, paddingBottom: 12}}
                  align={{top: 'bottom', right: 'right'}}
                  target={this.ref.current}
                  onClickOutside={() => this.setState({showOptions: false})}
                  onEsc={() => this.setState({showOptions: false})}>
                  <Box align="start">
                    <Button
                      fill="horizontal"
                      style={{padding: 8, display: 'flex'}}
                      plain
                      hoverIndicator="accent-4"
                      icon={<Add size="16px" />}
                      label="Create new endpoint"
                      onClick={() =>
                        this.setState({
                          endpointModalOpen: true,
                          showOptions: false,
                        })
                      }
                    />

                    <Button
                      fill="horizontal"
                      alignSelf="start"
                      style={{padding: 8, display: 'flex'}}
                      plain
                      hoverIndicator="accent-4"
                      onClick={logout}
                      icon={<Logout size="16px" />}
                      label="Logout"
                    />
                    <Text
                      style={{paddingLeft: 8, paddingRight: 8, paddingTop: 4}}
                      size="small">
                      Logged in as {login}
                    </Text>
                  </Box>
                </Drop>
              ) : null}
            </>
          );
        }}
      </Query>
    );
  }
}

function Header() {
  return (
    <>
      <Box
        gridArea="header"
        direction="row"
        align="center"
        justify="between"
        pad={{horizontal: 'medium', vertical: 'small'}}
        background="dark-1">
        <Link
          style={{
            color: 'white',
            textDecoration: 'none',
            display: 'flex',
            align: 'center',
            flexDirection: 'row',
          }}
          to="/">
          <Logo style={{height: 36, width: 36}} />{' '}
          <Text style={{lineHeight: '36px'}} size="large">
            WebSmee
          </Text>
        </Link>
        <Avatar />
      </Box>
    </>
  );
}

const CreateEndpointModal = withRouter(CreateEndpointModalImpl);

function Endpoints() {
  const [createEndpointModalOpen, setCreateEndpointModalOpen] = useState(false);
  return (
    <>
      {createEndpointModalOpen ? (
        <CreateEndpointModal
          onClose={() => setCreateEndpointModalOpen(false)}
        />
      ) : null}

      <Query query={endpointsQuery}>
        {({data, loading, error}) => {
          if (loading) {
            return null;
          }
          if (error) {
            return <Text>Error: {JSON.stringify(error, null, 2)}</Text>;
          }
          const endpoints = (oc(data).endpoints.nodes() || []).sort(
            (a, b) =>
              new Date(oc(b).lastRequestAt() || 0) -
              new Date(oc(a).lastRequestAt() || 0),
          );
          if (!endpoints.length) {
            return (
              <Box fill={true} align="center" pad="large">
                <Box>
                  <Box
                    width="medium"
                    fill={false}
                    align="center"
                    justify="center">
                    <Button
                      fill={false}
                      onClick={() => setCreateEndpointModalOpen(true)}
                      label="Create new endpoint"
                    />
                  </Box>
                </Box>
              </Box>
            );
          }
          return (
            <Box pad="large">
              <Box>
                <Heading level={3}>
                  Your endpoints
                  <Button
                    plain
                    margin={{left: '4px'}}
                    icon={<Add size="16px" />}
                    onClick={() => setCreateEndpointModalOpen(true)}
                  />
                </Heading>
                {endpoints.map(e => {
                  return (
                    <Box
                      style={{minHeight: 35}}
                      pad={{bottom: 'small'}}
                      margin={{vertical: 'medium'}}
                      key={e.id}>
                      <Link to={`/hook/${e.path}?_websmee_inspect`}>
                        <Text>{e.path}</Text>
                      </Link>
                      <Text size="small">
                        {e.lastRequestAt
                          ? `Last request ${distanceInWords(
                              new Date(e.lastRequestAt),
                              new Date(),
                            )} ago`
                          : 'No requests yet'}
                      </Text>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          );
        }}
      </Query>
    </>
  );
}

function Home() {
  useEffect(() => {
    document.title = 'WebSmee—WebHooks Debugger and Inspector';
  });
  return (
    <Query query={meQuery}>
      {({loading, error, data}) => {
        if (loading) {
          return null;
        }
        if (error) {
          return <Text>Error {JSON.stringify(error)}</Text>;
        }
        if (data.people.nodes.length) {
          return (
            <>
              <Box gridArea="no-sidebar-main" style={{overflow: 'scroll'}}>
                <Endpoints />
              </Box>
            </>
          );
        } else {
          return (
            <Box gridArea="no-sidebar-main">
              <Box fill={true} align="center">
                <Heading level={1}>
                  <Box direction="row" align="center">
                    <Logo style={{fill: '#000000'}} />
                    WebSmee
                  </Box>
                </Heading>
                <Heading level={2}>WebHooks{"'"} Right-Hand Man</Heading>
                <Box
                  width="medium"
                  fill={false}
                  align="center"
                  justify="center">
                  <Button
                    fill={false}
                    icon={<Github />}
                    label="Sign up to start debugging webhooks"
                    href="/oauth/github/start"
                  />
                  <Button
                    plain
                    fill={false}
                    label={<Text size="small">Sign in</Text>}
                    href="/oauth/github/start"
                  />
                </Box>
              </Box>
            </Box>
          );
        }
      }}
    </Query>
  );
}

function requestQuery(request) {
  return urlParse(request.requestUri, true).query;
}

const requestByIdQuery = gql`
  query RequestByIdQuery($id: UUID!) {
    request(id: $id) {
      id
      startedAt
      method
      requestUri
      httpVersion
      statusCode
      remoteAddress
      headers {
        name
        value
      }
      signedBodyUrl
    }
  }
`;

const MenuButton = ({label, open, ...rest}) => {
  const Icon = open ? FormDown : FormNext;
  return (
    <Button {...rest}>
      <Box direction="row" align="center" pad="xsmall">
        <Icon color="black" />
        <Text size="small" weight="bold">
          {label}
        </Text>
      </Box>
    </Button>
  );
};

function RequestLine({label, value}) {
  return (
    <span>
      <Text size="small" weight="bold">
        {label}:
      </Text>{' '}
      <Text size="small">{value}</Text>
    </span>
  );
}

function tryFormat({text, contentType}) {
  try {
    if (!text) {
      return {status: 'error'};
    }
    switch (contentType) {
      case 'application/json':
        return {
          result: JSON.stringify(JSON.parse(text), null, 2),
          status: 'ok',
        };
      case 'application/x-www-form-urlencoded':
        const params = new URLSearchParams(text);
        return {
          result: [...params.entries()]
            .map(([k, v]) => `${k}: ${v}`)
            .join('\n'),
          status: 'ok',
        };
      default:
        return {status: 'error'};
    }
  } catch (e) {
    return {status: 'error'};
  }
}

const useRequestBodyMemo = new WeakMap();

function useRequestBody(request) {
  const [body, setBody] = useState({text: null, contentType: null});
  const [loadingBody, setLoadingBody] = useState(true);
  const [error, setError] = useState('');
  useEffect(() => {
    const bodyUrl = request.signedBodyUrl;
    if (!bodyUrl) {
      setError('Error loading body');
      return;
    }
    setLoadingBody(true);
    setError('');
    const handleRequestFinished = resultPromise => {
      resultPromise
        .then(({aborted, text, contentType, error}) => {
          if (!aborted) {
            setBody({text, contentType});
            setError(error);
            setLoadingBody(false);
          }
        })
        .catch(e => console.error('useRequestBody error', e));
    };
    if (useRequestBodyMemo.has(request)) {
      handleRequestFinished(useRequestBodyMemo.get(request));
    } else {
      const req = new XMLHttpRequest();
      const resultPromise = new Promise((resolve, reject) => {
        req.addEventListener('load', () => {
          if (req.status === 200) {
            resolve({
              text: req.responseText,
              contentType:
                req.getResponseHeader('content-type') || 'text/plain',
              error: '',
            });
          } else {
            resolve({
              text: null,
              contentType: null,
              error: 'Error fetching body',
            });
          }
        });
        req.onabort = () => {
          resolve({aborted: true});
        };
        req.addEventListener('error', e => {
          console.error('error in request', e);
          reject('Error fetching body');
        });
      });
      useRequestBodyMemo.set(request, resultPromise);
      handleRequestFinished(resultPromise);
      req.open('GET', bodyUrl);
      req.setRequestHeader('Content-Type', 'text/plain');
      req.send();
      return () => {
        req.abort();
      };
    }
  }, [request]);
  return {body, loadingBody, error};
}

function RequestBody({request}) {
  const [payloadOpen, setPayloadOpen] = useState(true);
  const [showPlain, setShowPlain] = useState(false);
  const {body, loadingBody, error} = useRequestBody(request);
  const formatted = tryFormat(body);
  const displayText =
    formatted.status === 'ok' && !showPlain ? formatted.result : body.text;
  return (
    <div>
      <Box direction="row" align="center">
        <MenuButton
          open={payloadOpen}
          label="Request payload"
          onClick={() => setPayloadOpen(!payloadOpen)}
        />
        <Anchor
          title="View raw body"
          target="blank"
          rel="noopener noreferrer"
          href={request.signedBodyUrl}
          icon={<Share size="small" />}
        />
        {payloadOpen && formatted.status === 'ok' ? (
          <Text
            style={{marginLeft: 8, cursor: 'pointer'}}
            size="small"
            onClick={() => setShowPlain(!showPlain)}>
            {showPlain ? 'show parsed' : 'show plain'}
          </Text>
        ) : null}
      </Box>
      <Collapsible open={payloadOpen}>
        <Box
          margin={{left: 'medium'}}
          pad={{left: 'medium'}}
          justify="start"
          direction="row">
          {error ? (
            <Text size="small" color="status-critical">
              {error}
            </Text>
          ) : loadingBody ? (
            <Text size="small">Loading...</Text>
          ) : displayText ? (
            <Box margin={{top: 'small'}}>
              <CopyBox text={displayText} textToCopy={body.text} />
            </Box>
          ) : (
            <Text size="small">{'<none>'}</Text>
          )}
        </Box>
      </Collapsible>
    </div>
  );
}

function CopyBox({text, textToCopy}) {
  return (
    <Box margin={{right: 'small'}}>
      <Stack anchor="top-right">
        <Box
          fill={false}
          border={true}
          pad={{left: 'small', right: 'small'}}
          background="light-1">
          <Text size="small">
            <pre
              style={{
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
                paddingLeft: '0.5em',
                paddingRight: '0.5em',
              }}>
              {text}
            </pre>
          </Text>
        </Box>
        <Box
          style={{
            position: 'absolute',
            top: '-9px',
            left: '-9px',
          }}>
          <CopyToClipboard
            text={textToCopy != null ? textToCopy : text}
            size="18px"
            backgroundColor="white"
          />
        </Box>
      </Stack>
    </Box>
  );
}

function CopyRequest({request}) {
  const {body, loadingBody, error} = useRequestBody(request);
  let content;
  if (error) {
    content = (
      <Text size="small" color="status-critical">
        {error}
      </Text>
    );
  } else if (loadingBody) {
    content = <Text size="small">Loading...</Text>;
  } else {
    content = <CopyBox text={requestAsCurl(request, body.text)} />;
  }
  return (
    <Box
      margin={{left: 'medium', top: 'small'}}
      pad={{left: 'medium'}}
      justify="start"
      direction="row">
      {content}
    </Box>
  );
}

function RequestLineDate({startedAt}) {
  const now = useDate();
  return `${new Date(startedAt).toLocaleString()} (${distanceInWords(
    new Date(startedAt),
    now,
  )} ago)`;
}

const Request = React.memo(({requestId}) => {
  const [generalOpen, setGeneralOpen] = useState(true);
  const [headersOpen, setHeadersOpen] = useState(true);
  const [queryParamsOpen, setQueryParamsOpen] = useState(true);
  const [showCopyRequest, setShowCopyRequest] = useState(false);
  return (
    <Query query={requestByIdQuery} variables={{id: requestId}}>
      {({data, loading, error}) => {
        if (loading) {
          return null;
        }
        if (error) {
          return <div>Error {JSON.stringify(error, null, 2)}</div>;
        }
        if (!data.request) {
          return (
            <Box margin="medium">
              <Text color="status-error">Request not found</Text>
            </Box>
          );
        }
        const request = data.request;
        const query = requestQuery(request);
        return (
          <Box
            margin={{top: 'medium', bottom: 'medium'}}
            pad={{top: 'small', bottom: 'small'}}
            border="top">
            <div>
              <MenuButton
                open={showCopyRequest}
                label="Copy request"
                onClick={() => setShowCopyRequest(!showCopyRequest)}
              />
              <Collapsible open={showCopyRequest}>
                <CopyRequest request={request} />
              </Collapsible>
            </div>
            <div>
              <MenuButton
                open={generalOpen}
                label="General"
                onClick={() => setGeneralOpen(!generalOpen)}
              />
              <Collapsible open={generalOpen}>
                <Box margin={{left: 'medium'}} pad={{left: 'medium'}}>
                  <RequestLine label="Request url" value={request.requestUri} />
                  <RequestLine
                    label="Date"
                    value={
                      <RequestLineDate
                        startedAt={new Date(request.startedAt)}
                      />
                    }
                  />
                  <RequestLine label="Request method" value={request.method} />
                  <RequestLine label="Status code" value={request.statusCode} />
                  <RequestLine
                    label="Remote address"
                    value={request.remoteAddress}
                  />
                </Box>
              </Collapsible>
            </div>
            <div>
              <MenuButton
                open={headersOpen}
                label="Request headers"
                onClick={() => setHeadersOpen(!headersOpen)}
              />
              <Collapsible open={headersOpen}>
                <Box margin={{left: 'medium'}} pad={{left: 'medium'}}>
                  {request.headers.map((header, i) => (
                    <RequestLine
                      key={i}
                      label={header.name}
                      value={header.value}
                    />
                  ))}
                </Box>
              </Collapsible>
            </div>
            <div>
              <MenuButton
                label="Query params"
                open={setQueryParamsOpen}
                onClick={() => setQueryParamsOpen(!queryParamsOpen)}
              />
              <Collapsible open={queryParamsOpen}>
                <Box margin={{left: 'medium'}} pad={{left: 'medium'}}>
                  {Object.keys(query).length === 0 ? (
                    <Text size="small">{'<none>'}</Text>
                  ) : null}
                  {Object.keys(query).map(k => (
                    <RequestLine key={k} label={k} value={query[k]} />
                  ))}
                </Box>
              </Collapsible>
            </div>
            <RequestBody request={request} />
            {/* spacer */}
            <Box pad="medium" />
          </Box>
        );
      }}
    </Query>
  );
});

let __uniqueId = 0;
function genId() {
  return (__uniqueId++).toString(36);
}

let listeners = {};

let NOW = new Date();

setInterval(() => {
  NOW = new Date();
  for (const k of Object.keys(listeners)) {
    listeners[k]();
  }
}, 1000);

function useDate() {
  const savedCallback = useRef();
  const [currentDate, setDate] = useState(NOW);
  useEffect(() => {
    const id = genId();
    listeners[id] = () => setDate(NOW);
    return () => {
      delete listeners[id];
    };
  }, [savedCallback]);
  return currentDate;
}

function RequestSummaryUpdated({startedAt}) {
  const now = useDate();
  return `${distanceInWordsStrict(startedAt, now, {
    includeSeconds: true,
  })} ago`;
}

function RequestSummary({path, request, isActive, onClick}) {
  const [showDelete, setShowDelete] = useState(false);
  return (
    <Stack
      anchor="right"
      onMouseOver={() => setShowDelete(true)}
      onMouseLeave={() => setShowDelete(false)}>
      <Link
        onClick={onClick}
        to={`/hook/${path}/${request.id}?_websmee_inspect`}
        style={{
          color: 'black',
          textDecoration: 'none',
          cursor: 'pointer',
          height: SUMMARY_BOX_HEIGHT,
        }}>
        <Box
          direction="row"
          justify="start"
          align="center"
          height={`${SUMMARY_BOX_HEIGHT}px`}
          pad="medium"
          gap="xsmall"
          border={{color: 'light-1', size: 'small', side: 'bottom'}}
          {...(isActive ? {background: 'accent-3'} : {elevation: 'none'})}>
          <Text
            size="small"
            style={{
              whiteSpace: 'nowrap',
              wordWrap: 'break-word',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}>
            {request.method} {request.id.split('-')[0]} (
            <RequestSummaryUpdated startedAt={new Date(request.startedAt)} />)
          </Text>
        </Box>
      </Link>
      {showDelete ? (
        <Mutation
          mutation={deleteRequestMutation}
          update={(cache, {data}) => {
            const {endpointByPath} = cache.readQuery({
              query: endpointByPathQuery,
              variables: {path},
            });
            cache.writeQuery({
              query: endpointByPathQuery,
              variables: {path},
              data: {
                endpointByPath: {
                  ...endpointByPath,
                  requests: {
                    ...endpointByPath.requests,
                    nodes: endpointByPath.requests.nodes.filter(
                      x => x.id !== request.id,
                    ),
                  },
                },
              },
            });
          }}>
          {(deleteRequest, {loading, error}) => (
            <Button
              onClick={() => deleteRequest({variables: {id: request.id}})}
              className="delete-request-button"
              margin="small"
              plain
              icon={<FormTrash color={isActive ? 'white' : 'status-error'} />}
            />
          )}
        </Mutation>
      ) : null}
    </Stack>
  );
}

function curlText(path) {
  return `curl -X POST -H 'Content-Type: application/json' ${'\\'}
${'  '}'${hookUrl(path)}' ${'\\'}
${'  '}-d '{"hello": "world"}'
`;
}

function requestAsCurl(request, body) {
  const method = request.method;

  const headerStr = request.headers
    .map(h => quote(`${h.name}: ${h.value}`))
    .reduce((acc, s) => acc + ' -H ' + s, '');
  const bodyStr = body != null ? `-d ${quote(body)}` : '';
  return `curl -X ${method} ${headerStr} ${quote(
    BASE_URL + request.requestUri,
  )} ${bodyStr}`
    .trim()
    .replace(/\s+/g, ' ');
}

const requestSummaryFragment = gql`
  fragment RequestSummary on Request {
    id
    method
    startedAt
    endpointId
  }
`;

const endpointSummaryFragment = gql`
  fragment EndpointSummary on Endpoint {
    id
    isPublic
    viewerCanEdit
    path
    contentType
    statusCode
    responseBody
  }
`;

const endpointByPathQuery = gql`
  query EndpointByPath($path: String!, $requestsCursor: Cursor) {
    endpointByPath(path: $path) {
      ...EndpointSummary
      requests(first: 20, orderBy: STARTED_AT_DESC, after: $requestsCursor) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          ...RequestSummary
        }
      }
    }
  }
  ${requestSummaryFragment}
  ${endpointSummaryFragment}
`;

const newRequestSubscription = gql`
  subscription NewRequestSubscription($endpointId: UUID!) {
    requestCreated(endpointId: $endpointId) {
      request {
        ...RequestSummary
      }
    }
  }
  ${requestSummaryFragment}
`;

const updateEndpointMutation = gql`
  mutation UpdateEndpointMutation(
    $endpointId: UUID!
    $endpoint: EndpointPatch!
  ) {
    updateEndpoint(input: {id: $endpointId, patch: $endpoint}) {
      endpoint {
        ...EndpointSummary
      }
    }
  }
  ${endpointSummaryFragment}
`;

function EditPublic({endpoint}) {
  return (
    <Mutation mutation={updateEndpointMutation}>
      {(updateEndpoint, {loading, error}) => (
        <FormField
          error={
            error ? (
              <Text size="small">Error changing setting {error.message}</Text>
            ) : (
              undefined
            )
          }>
          <CheckBox
            pad="medium"
            label={<Text size="small">Public</Text>}
            checked={endpoint.isPublic}
            disabled={loading}
            onChange={e =>
              updateEndpoint({
                variables: {
                  endpointId: endpoint.id,
                  endpoint: {isPublic: !endpoint.isPublic},
                },
              })
            }
          />
        </FormField>
      )}
    </Mutation>
  );
}

function EditStatusCode({endpoint}) {
  const initialStatusCode = endpoint.statusCode + '';
  const [statusCode, setStatusCode] = useState(initialStatusCode);
  const changed = initialStatusCode !== statusCode;

  return (
    <Mutation mutation={updateEndpointMutation}>
      {(updateEndpoint, {loading, error}) => (
        <FormField
          style={{alignItems: 'flex-start'}}
          error={
            error ? (
              <Text size="small">Error changing setting {error.message}</Text>
            ) : (
              undefined
            )
          }
          label={
            <>
              <Text size="small">Response code</Text>
              <Button
                diabled={loading}
                size="small"
                label="Save"
                margin={{left: 'small'}}
                onClick={() =>
                  updateEndpoint({
                    variables: {
                      endpointId: endpoint.id,
                      endpoint: {statusCode: parseInt(statusCode, 10)},
                    },
                  })
                }
                style={{visibility: changed ? 'visible' : 'hidden'}}
              />
            </>
          }
          name="response-code"
          validate={value => {
            if (value && !value.match(/[0-9][0-9][0-9]/i)) {
              return 'Response code must be a 3-digit HTTP response code.';
            }
          }}>
          <TextInput
            size="small"
            style={{width: '9ch'}}
            value={statusCode}
            onChange={e => setStatusCode(e.target.value)}
          />
        </FormField>
      )}
    </Mutation>
  );
}

function EditContentType({endpoint}) {
  const initialContentType = endpoint.contentType;
  const [contentType, setContentType] = useState(initialContentType);
  const changed = initialContentType !== contentType;

  return (
    <Mutation mutation={updateEndpointMutation}>
      {(updateEndpoint, {loading, error}) => (
        <FormField
          style={{alignItems: 'flex-start'}}
          error={
            error ? (
              <Text size="small">Error changing setting {error.message}</Text>
            ) : (
              undefined
            )
          }
          label={
            <>
              <Text size="small">Response Content Type</Text>
              <Button
                diabled={loading}
                size="small"
                label="Save"
                margin={{left: 'small'}}
                onClick={() =>
                  updateEndpoint({
                    variables: {
                      endpointId: endpoint.id,
                      endpoint: {contentType: contentType},
                    },
                  })
                }
                style={{visibility: changed ? 'visible' : 'hidden'}}
              />
            </>
          }
          name="content-type"
          validate={value => {
            if (value.length > 1024) {
              return 'Content type length is too long.';
            }
          }}>
          <TextInput
            size="small"
            value={contentType}
            onChange={e => setContentType(e.target.value)}
          />
        </FormField>
      )}
    </Mutation>
  );
}

function EditResponseBody({endpoint}) {
  const initialResponseBody = endpoint.responseBody;
  const [responseBody, setResponseBody] = useState(initialResponseBody);
  const changed = initialResponseBody !== responseBody;

  return (
    <Mutation mutation={updateEndpointMutation}>
      {(updateEndpoint, {loading, error}) => (
        <FormField
          style={{alignItems: 'flex-start'}}
          error={
            error ? (
              <Text size="small">Error changing setting {error.message}</Text>
            ) : (
              undefined
            )
          }
          label={
            <>
              <Text size="small">Response Body</Text>
              <Button
                diabled={loading}
                size="small"
                label="Save"
                margin={{left: 'small'}}
                onClick={() =>
                  updateEndpoint({
                    variables: {
                      endpointId: endpoint.id,
                      endpoint: {responseBody: responseBody},
                    },
                  })
                }
                style={{visibility: changed ? 'visible' : 'hidden'}}
              />
            </>
          }
          name="body"
          validate={value => {
            if (value.length > 1024) {
              return 'Response body length is too long.';
            }
          }}>
          <TextArea
            size="small"
            value={responseBody}
            onChange={e => setResponseBody(e.target.value)}
          />
        </FormField>
      )}
    </Mutation>
  );
}

function EndpointBody(props) {
  const [instructionsOpen, setInstructionsOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const {path, subscribeToMore, endpoint, fetchMore} = props;
  const requests = oc(endpoint).requests.nodes() || [];
  const lastRequestId = oc(requests)[0].id();
  const activeRequestId = props.requestId || lastRequestId;
  const hasNextPage = oc(endpoint).requests.pageInfo.hasNextPage();
  const isItemLoaded = idx => !hasNextPage || idx < requests.length;
  const endpointId = oc(endpoint).id();
  const requestsSinceVisible = React.useRef(requests);

  if (document.visibilityState === 'visible') {
    requestsSinceVisible.current = requests;
  }

  useEffect(() => {
    FAVICON.reset();
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        FAVICON.reset();
        requestsSinceVisible.current = requests;
      }
    };
    window.addEventListener('visibilitychange', handleVisibilityChange);
    return () =>
      window.removeEventListener('visibilitychange', handleVisibilityChange);
  }, [path, requests]);
  useEffect(() => {
    if (requestsSinceVisible.current !== requests) {
      FAVICON.badge(requests.length - requestsSinceVisible.current.length);
    }
  }, [requests]);
  useEffect(() => {
    if (endpointId) {
      return subscribeToMore({
        document: newRequestSubscription,
        variables: {endpointId},
        updateQuery: (prev, {subscriptionData, variables}) => {
          const newRequest = oc(subscriptionData).data.requestCreated.request();
          if (!newRequest || newRequest.endpointId !== endpointId) {
            return prev;
          }
          const prevNodes = prev.endpointByPath.requests.nodes;
          if (prevNodes.find(n => n.id === newRequest.id)) {
            return prev;
          }
          return Object.assign({}, prev, {
            ...prev,
            endpointByPath: {
              ...prev.endpointByPath,
              requests: {
                ...prev.endpointByPath.requests,
                nodes: [newRequest, ...prev.endpointByPath.requests.nodes].sort(
                  (a, b) =>
                    new Date(b.startedAt).getTime() -
                    new Date(a.startedAt).getTime(),
                ),
              },
            },
          });
        },
      });
    }
    // eslint-disable-next-line
  }, [endpointId]);
  if (!endpoint) {
    return (
      <Box
        gridArea="no-sidebar-main"
        style={{width: '100vw'}}
        fill
        pad="medium"
        align="center">
        <Heading>Endpoint not found</Heading>
        <Query query={meQuery}>
          {({data, loading, error}) => {
            if (!loading && data && !oc(data).people.nodes.length()) {
              return (
                <Button
                  icon={<Github />}
                  fill={false}
                  label="Sign in"
                  href={`/oauth/github/start?redirectPath=/hook/${path}${
                    activeRequestId ? '/' + activeRequestId : ''
                  }`}
                />
              );
            }
            return null;
          }}
        </Query>
      </Box>
    );
  }

  const loadMoreItems = async () => {
    setIsNextPageLoading(true);
    try {
      await fetchMore({
        variables: {
          path,
          requestsCursor: endpoint.requests.pageInfo.endCursor,
        },
        updateQuery: (previousResult, {fetchMoreResult}) => {
          const newNodes = fetchMoreResult.endpointByPath.requests.nodes;
          const pageInfo = fetchMoreResult.endpointByPath.requests.pageInfo;
          const res = {
            ...previousResult,
            endpointByPath: {
              ...previousResult.endpointByPath,
              requests: {
                ...previousResult.endpointByPath.requests,
                nodes: [
                  ...previousResult.endpointByPath.requests.nodes,
                  ...newNodes,
                ],
                pageInfo,
              },
            },
          };
          setIsNextPageLoading(false);
          return res;
        },
      });
    } catch (e) {
      setIsNextPageLoading(false);
    }
  };
  const followRequests = !props.requestId;
  return (
    <>
      <Box gridArea="sidebar" style={{overflow: 'scroll'}}>
        {lastRequestId ? (
          <Box
            style={{minHeight: 35}}
            direction="row"
            justify="start"
            pad="medium"
            gap="xsmall"
            border={{color: 'light-1', size: 'small', side: 'bottom'}}>
            <CheckBox
              pad="medium"
              label="Follow"
              checked={followRequests}
              toggle
              onChange={e =>
                followRequests
                  ? props.history.replace(
                      `/hook/${path}/${lastRequestId}?_websmee_inspect`,
                    )
                  : props.history.replace(`/hook/${path}?_websmee_inspect`)
              }
            />
          </Box>
        ) : null}
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          minimumBatchSize={20}
          threshold={10}
          itemCount={hasNextPage ? requests.length + 1 : requests.length}
          loadMoreItems={isNextPageLoading ? () => {} : loadMoreItems}>
          {({onItemsRendered, ref}) => (
            <FixedSizeList
              height={Math.min(
                oc(window).screen.height() || 1000,
                SUMMARY_BOX_HEIGHT * requests.length,
              )}
              itemSize={SUMMARY_BOX_HEIGHT}
              itemCount={hasNextPage ? requests.length + 1 : requests.length}
              onItemsRendered={onItemsRendered}
              ref={ref}>
              {({index, style}) => {
                const request = requests[index];
                if (!request) {
                  return (
                    <Box align="center" justify="center" style={{...style}}>
                      <Text size="small">Loading...</Text>
                    </Box>
                  );
                }
                return (
                  <Box style={{...style, overflow: 'hidden'}}>
                    <RequestSummary
                      key={request.id}
                      request={request}
                      path={path}
                      isActive={request.id === activeRequestId}
                      onClick={() => {}}
                    />
                  </Box>
                );
              }}
            </FixedSizeList>
          )}
        </InfiniteLoader>
        {requests.length === 0 ? (
          <Box direction="row" justify="start" pad="medium">
            <Text size="small">Listening...</Text>
          </Box>
        ) : null}
      </Box>
      <Box
        elevation="medium"
        gridArea="main"
        pad="medium"
        style={{overflow: 'scroll'}}>
        <Heading level={2} size={'small'}>
          <span>
            {hookUrl(path)} <CopyToClipboard text={hookUrl(path)} />
          </span>
        </Heading>
        {endpoint.viewerCanEdit ? (
          <div>
            <MenuButton
              open={settingsOpen}
              label="Settings"
              onClick={() => setSettingsOpen(!settingsOpen)}
            />

            <Collapsible open={settingsOpen}>
              <Box
                margin={{left: 'medium', top: 'small', bottom: 'small'}}
                pad={{left: 'medium'}}
                justify="start"
                direction="column"
                style={{alignItems: 'flex-start'}}>
                <ThemeContext.Extend
                  value={{
                    checkBox: {size: '16px'},
                    formField: {
                      label: {margin: {left: 'none'}},
                    },
                    textArea: {
                      extend: 'font-size: 14px',
                    },
                    text: {
                      medium: {
                        size: '14px',
                        height: '20px',
                      },
                    },
                    button: {
                      padding: {
                        horizontal: '12px',
                        vertical: '2px',
                      },
                    },
                  }}>
                  <ThemeContext.Extend
                    value={{
                      formField: {
                        border: {position: 'none'},
                      },
                    }}>
                    <EditPublic endpoint={endpoint} />
                  </ThemeContext.Extend>
                  <EditStatusCode endpoint={endpoint} />
                  <EditContentType endpoint={endpoint} />
                  <EditResponseBody endpoint={endpoint} />
                </ThemeContext.Extend>
              </Box>
            </Collapsible>
          </div>
        ) : null}

        <div>
          <MenuButton
            open={instructionsOpen}
            label="Instructions"
            onClick={() => setInstructionsOpen(!instructionsOpen)}
          />
          <Collapsible open={instructionsOpen}>
            <Box
              margin={{left: 'medium', top: 'small'}}
              pad={{left: 'medium'}}
              justify="start"
              direction="row">
              <CopyBox text={curlText(path)} />
            </Box>
          </Collapsible>
        </div>
        {activeRequestId ? <Request requestId={activeRequestId} /> : null}
      </Box>
    </>
  );
}

function EndpointPage(props) {
  const requestId = props.match.params.requestId;
  const path = props.match.params.path;
  useEffect(() => {
    document.title = path;
  }, [path]);
  return (
    <Query query={endpointByPathQuery} variables={{path}}>
      {({subscribeToMore, error, networkStatus, data, fetchMore}) => {
        if (networkStatus === 1) {
          return null;
        }
        if (error && !(data && Object.keys(data).length > 0)) {
          return (
            <div>
              There was an error <pre>{JSON.stringify(error, null, 2)}</pre>
            </div>
          );
        }
        return (
          <EndpointBody
            subscribeToMore={subscribeToMore}
            fetchMore={fetchMore}
            requestId={requestId}
            path={path}
            endpoint={data.endpointByPath}
            history={props.history}
          />
        );
      }}
    </Query>
  );
}

function NotFound(props) {
  useEffect(() => {
    document.title = 'Page not found';
  });

  return (
    <Box style={{width: '100vw'}} fill pad="medium" align="center">
      <Heading>Page not found</Heading>
    </Box>
  );
}

function App() {
  return (
    <Grommet theme={hp} full>
      <ApolloProvider client={client}>
        <ResponsiveContext.Consumer>
          {size => (
            <Grid
              fill
              areas={[
                {name: 'header', start: [0, 0], end: [1, 0]},
                {name: 'no-sidebar-main', start: [0, 1], end: [1, 1]},
                {name: 'sidebar', start: [0, 2], end: [0, 2]},
                {name: 'main', start: [1, 2], end: [1, 2]},
              ]}
              rows={['auto', 'auto', 'flex']}
              columns={[size === 'small' ? '80px' : '280px', 'flex']}
              gap="none">
              <Router>
                <Header />
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/hook/:path" component={EndpointPage} />
                  <Route
                    exact
                    path="/hook/:path/inspect"
                    component={EndpointPage}
                  />
                  <Route
                    exact
                    path="/hook/:path/:requestId/inspect"
                    component={EndpointPage}
                  />
                  <Route
                    exact
                    path="/hook/:path/:requestId"
                    component={EndpointPage}
                  />

                  <Route
                    exact
                    path="/hook/:path/:requestId"
                    component={EndpointPage}
                  />

                  <Route component={NotFound} />
                </Switch>
              </Router>
            </Grid>
          )}
        </ResponsiveContext.Consumer>
      </ApolloProvider>
    </Grommet>
  );
}

export default App;
