import {css} from 'styled-components';
import {hpsimplified} from './hpsimplifiedwoff';

export const hp = {
  collapsible: {
    minSpeed: 100,
    baseline: Number.MAX_SAFE_INTEGER,
  },
  global: {
    colors: {
      background: '#ffffff',
      brand: '#0096D6',
      control: {
        dark: '#00A8F0',
        light: '#0096D6',
      },
      focus: '#99d5ef',
      'neutral-1': '#006996',
      'neutral-2': '#A65336',
      'neutral-3': '#A69136',
      'neutral-4': '#774677',
      'accent-1': '#E6734B',
      'accent-2': '#E6C84B',
      'accent-3': '#915591',
      'status-critical': '#F04B37',
      'status-warning': '#F0AA3C',
      'status-ok': '#509137',
      'status-unknown': '#848484',
      'status-disabled': '#848484',
      'dark-1': '#000001',
      'dark-2': '#676767',
      'light-1': '#F2F2F2',
      'light-2': '#E8E8E8',
      'light-3': '#CCCCCC',
    },
    font: {
      family: "'HPSimplified', Arial, sans-serif",
      face: `
        @font-face {
          font-family: 'HPSimplified';
          src:
            local('HPSimplified'),
            url(data:application/x-font-woff;charset=utf-8;base64,${hpsimplified}) format('woff');
        }
      `,
    },
  },
  anchor: {
    color: {
      dark: '#00A8F0',
      light: '#2883d7',
    },
  },
  button: {
    extend: css`
      ${props =>
        !props.plain &&
        `
        font-weight: bold;
        border-radius: 0 6px;
      `}
    `,
  },
};
